import {createApp, ref} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import router from './router'
import store from './store/store'
// import { createMetaManager } from 'vue-meta'
import Maska from 'maska';
import moment from 'moment'
import 'moment/locale/ru'



loadFonts()

let app = createApp(App);

const urlParams = new URL(window.location.href);
if (urlParams.searchParams.has('source') && localStorage.getItem('customerSource') === null) {
  localStorage.setItem('customerSource', urlParams.searchParams.get('source'));
} else {
  if (document.referrer) {
    const refUrl = new URL(document.referrer);
    localStorage.setItem('customerSource', refUrl.host);
  }
}

moment.locale('ru');
app.config.globalProperties.$moment = moment

app.config.globalProperties.$config = window.config

window.yandexClientID = ref(sessionStorage.getItem('yandexClientID'))

const getYandexClientID = () => {
  function __get() {
    return window.yandexClientID.value;
  }

  if (!__get() && window.ym && window.config.yandexMetrikaId) {
    window.ym(window.config.yandexMetrikaId, 'getClientID', function (clientID) {
      window.yandexClientID.value = clientID;
    });
  }

  return __get()
}

Object.defineProperty(app.config.globalProperties, '$yandexClientID', {
  get() {
    return getYandexClientID()
  }
})

app.config.globalProperties.$urlToNewLk = (url = '') => {
  const newUrl = new URL(url, window.config.newLkBaseUrl)
  const __yandexClientID = getYandexClientID()

  if (__yandexClientID) {
    newUrl.searchParams.append('yandex_client_id', __yandexClientID)
  }

  newUrl.searchParams.append('utm_source', window.location.hostname)

  return newUrl.toString()
}

app.use(router)
  .use(vuetify)
  .use(VueSplide)
  .use(store)
  .use(moment)
  .use(Maska)
  // .use(createMetaManager()) // add this line
  .mount('#app');


  app.config.globalProperties.$display = vuetify.display;

  window.vueApp = app;

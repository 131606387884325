import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/layouts/MainLayout.vue'),
    children: [
      {
        path: '/:pathMatch(.*)*', 
        redirect: '/404'
      },
      { 
        path: '/404', 
        name: 'NotFound',  
        component: () => import('../views/NotFoundView.vue'),
      },
      { 
          path: '/', 
          name: 'HomeView', 
          component: () => import('../views/HomeView.vue'),
          meta: { 
            title: 'Семейный медицинский центр «Доктор знает»',
            metaTags: [
              {
                name: 'description',
                content: 'The home page of our example app.'
              },
              {
                property: 'og:description',
                content: 'The home page of our example app.'
              }
            ]
          }
      },
      { 
        path: '/about', 
        name: 'AboutView', 
        component: () => import('../views/AboutView.vue'),
        meta: { 
          title: 'О нас' 
        },
        beforeEnter: () => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk('/about')
        }
      },
      { 
        path: '/vacancy', 
        name: 'VacancyView', 
        component: () => import('../views/VacancyView.vue'),
        meta: { 
          title: 'Вакансии' 
        }
      },
      { 
        path: '/contacts', 
        name: 'ContactsView', 
        component: () => import('../views/ContactsView.vue'),
        meta: { 
          title: 'Контакты' 
        }
      },
      { 
        path: '/docs', 
        name: 'DocumentsView', 
        component: () => import('../views/DocumentsView'),
        meta: { 
          title: 'Документы' 
        }
      },
      { 
        path: '/insurance', 
        name: 'InsuranceView', 
        component: () => import('../views/InsuranceView'),
        meta: { 
          title: 'Страховые компании' 
        }
      },
      { 
        path: '/docs', 
        name: 'DocumentsView', 
        component: () => import('../views/DocumentsView'),
        meta: { 
          title: 'Документы' 
        }
      },
      { 
        path: '/reviews', 
        name: 'ReviewsView', 
        component: () => import('../views/ReviewsView'),
        meta: { 
          title: 'Отзывы' 
        },
        beforeEnter: () => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk('/reviews')
        }
      },
      { 
        path: '/price', 
        name: 'PriceView', 
        component: () => import('../views/PriceView'),
        meta: { 
          title: 'Услуги' 
        },
        beforeEnter: () => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk('/services')
        }
      },
      { 
        path: '/create-appointment',
        alias: '/appointment',
        name: 'CreateAppointmentView', 
        component: () => import('../views/CreateAppointmentView'),
        meta: { 
          title: 'Запись на прием' 
        },
        beforeEnter: () => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk('/record')
        },
        children: [
					{ 
						path: '', 
						name: 'ClientSelect', 
						component: () => import('../views/createAppointment/Clients'),
					},
					{
						path: 'filial',
						name: 'FilialSelect',
						component: () => import('../views/createAppointment/FilialsSelect'),
					},
					{ 
						path: 'direction', 
						name: 'DirectionSelect', 
						component: () => import('../views/createAppointment/Directions'),
					},
					{ 
            path: 'users', 
            name: 'UserSelect', 
            component: () => import('../views/createAppointment/UsersView'),
					},
					{ 
							path: 'services', 
							name: 'ServiceSelect', 
							component: () => import('../views/createAppointment/ServicesView'),
					},
					{ 
						path: 'dd', 
						name: 'UserInfo', 
						component: () => import('../views/createAppointment/UserView'),
					},
					{ 
							path: 'users-by-service', 
							name: 'UsersByService', 
							component: () => import('../views/createAppointment/UsersByService'),
					},
					{ 
							path: 'apply-appointment', 
							name: 'ApplyAppointment', 
							component: () => import('../views/createAppointment/ApplyAppointment'),
					},
				]
      },
      { 
        path: '/news', 
        name: 'NewsListView', 
        component: () => import('../views/NewsListView'),
        meta: { 
          title: 'Новости' 
        },
        beforeEnter: () => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk('/news')
        }
      },
      { 
        path: '/promotions', 
        name: 'PromotionsListView', 
        component: () => import('../views/PromotionsListView'),
        meta: { 
          title: 'Акции' 
        },
        beforeEnter: () => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk('/promotions')
        }
      },
      { 
        path: '/doctors', 
        name: 'UsersListView', 
        component: () => import('../views/UsersListView'),
        meta: { 
          title: 'Врачи' 
        },
        beforeEnter: () => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk('/users')
        }
      },
      { 
        path: '/news/:id', 
        name: 'NewsView', 
        component: () => import('../views/NewsView'),
        beforeEnter: (to) => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk(`/news/${to.params.id}`)
        }
      },
      { 
        path: '/promotions/:id', 
        name: 'PromotionView', 
        component: () => import('../views/PromotionView'),
        beforeEnter: (to) => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk(`/promotions/${to.params.id}`)
        }
      },
      { 
        path: '/doctors/:id', 
        name: 'UserView', 
        component: () => import('../views/UserView'),
        beforeEnter: (to) => {
          window.location.href = window.vueApp.config.globalProperties.$urlToNewLk(`/users/${to.params.id}`)
        }
      },
      /*{
        path: '/test/q',
        name: 'PromotionView',
        component: () => import('../views/PromotionView'),
      },*/
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  document.title =  to.meta.title || '';
  // document.description = to.meta.title || '';
  next();
});

export default router
